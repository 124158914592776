@import url('https://fonts.googleapis.com/css2?family=Inter&family=Lato&family=Montserrat:ital,wght@0,800;1,700&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

*{
  margin:0;
  padding:0;
}
::-webkit-scrollbar{
  display: none;
}
.App {
  justify-content: center;
  overflow: hidden;
  background-color: rgb(32, 38, 45);
  -webkit-tap-highlight-color: transparent;
}
.AppHeader{
  height: 9vh;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 10px 0 0 1.6vh;
  color:white;
}
.AppHeader-right{
  display: flex;
  align-items: center;
}
.AppHeader-right small{
  color:white;
  font-family: 'Montserrat', sans-serif;
  margin-left: 3vh;
  cursor: pointer;
  text-decoration: none;
  padding-left: 3vh;
  border-left: 3px solid white;
}
.calculator-link:hover{
  text-decoration: underline;
}

.App-title{
  color:white;
  font-size: 4vh;
  font-family: 'Montserrat', sans-serif;
}
.App-inner{
  width: 95.5%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}
.card{
  height: 337px;
  min-height: 37vh;
  width: 33.3333%;
  padding: 1.6vh;
  box-sizing: border-box;
  flex-shrink: 0;
}
@media screen and (max-width:1096px){
  .card{
    width: 50%;
  }
}
@media screen and (max-width:770px){
  .card{
    width: 100%;
  }
  .App-inner{
    padding-top: 2vw;
    width: 92%;
  }
  .AppHeader{
    width: 92%;
    padding: 10px 0 0 5vw;
  }
  .App-title{
    color:white;
    font-size: 6.5vw;
    font-family: 'Montserrat', sans-serif;
  }
  .AppHeader-right{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .AppHeader-right small{
    margin-left: 0px;
    padding-left: 0px;
    border-left: none;
    font-size: 4vw;
  }
  .AppHeader-linkHolder{
    margin-top: 2.5vw;
  }
 
  .calculator-link{
    padding-left: 2vh !important;
    margin-left: 2vh !important;
    border-left: 3px solid white !important;
  }
}

.cardInner{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 0.8vh;
  box-shadow: 0px 4px 20px black;
}
.card img, .card video{
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.cardInner-bottom{
  display: flex;
  height: 40px;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  bottom:0;
  align-items: center;
}
.cardInner-bottom>span{
  color:white;
  font-size: 2vh;
  font-family: 'Lato', sans-serif;
}
.modalImg{
  object-fit: scale-down;
  max-height: 70vh;
  max-width: 78vw;
 
}